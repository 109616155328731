import featherIconUrl from 'feather-icons/dist/feather-sprite.svg?url';

class FeatherIcon extends HTMLElement {
  connectedCallback() {
    this.render();
  }

  render() {
    const name = this.getAttribute('name');
    const className = this.getAttribute('class');

    this.innerHTML = /* html */ `
      <svg class="feather feather-${name} ${className || ''}">
        <use href="${featherIconUrl}#${name}"></use>
      </svg>
    `;
  }
}

class FeatherIconBubble extends HTMLElement {
  connectedCallback() {
    this.render();
  }

  render() {
    const iconName = this.getAttribute('icon_name');
    const className = this.getAttribute('class');
    const label = this.getAttribute('label');

    this.innerHTML = /* html */ `
    <span class="feather-bubble ${className ?? ''}">
        <feather-icon name="${iconName}"></feather-icon>
        ${label || ''}
      </span>
    `;
  }
}

customElements.define('feather-icon', FeatherIcon);
customElements.define('feather-icon-bubble', FeatherIconBubble);
